<template>
    <div class="footer-wrapper">
        <div class="footer-btns-wrapper">
            <router-link tag="span" :class="{'scan-btn icon-scan': true, 'translucide': disableScanBtn}" :to="'scan'" :event="!disableScanBtn ? 'click' : ''"></router-link>
            <div :class="{'footer-btn': true, 'translucide': basketIsEmpty || disableCheckoutButton || isOffline, 'smallTextSize': isOffline}" @click="handleCheckoutClick">
                <template v-if="!inPaymentMethods">
                    <span class="label" v-if="isOffline">{{$t("offlineCheckoutText")}}</span>
                    <span class="label" v-else-if="!basketIsEmpty">{{$t("basketFooterText")}}</span>
                    <span v-else class="label">{{$t("basketEmptyFooterText")}}</span>
                </template>
                <template v-else>
                    <span class="label">{{$t("pay")}} {{formatPrice(basket.totalAmount)}}</span>
                </template>
            </div>
        </div>
        <b-modal centered
                size="sm"
                header-text-variant="light"
                no-close-on-backdrop
                no-close-on-esc
                hide-header
                hide-footer
                style="padding: 0;"
                v-model="showCheckoutModal">

            <div class="food-custom-modal">
                <span class="food-custom-modal-header">{{$t("loyaltyCardModalHeader")}}</span>
                <span class="food-custom-modal-text" v-html="$t('loyaltyCardModalText')"></span>
                <div class="delete-modal-actions">
                    <button class="btn nok" @click="toPaymentMethod">{{$t("loyaltyCardModalBtnNok")}}</button>
                    <button class="btn ok" @click="toLoyaltyScan">{{$t("loyaltyCardModalBtnOk")}}</button>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
    var basket = require('@/assets/js/Food/Basket');
    import {getLocalBasket, decreaseProductFromLocalBasket} from "@/assets/js/Food/Basket";
    import {formatPrice} from "@/assets/js/Utils";

    export default {
        name:"FooterComponent",
        props:{
            scannedProduct: Object,
            inPaymentMethods: {
                type: Boolean,
                default: false
            },
            disableCheckoutButton: {
                type: Boolean,
                default: false
            },
            disableScanBtn: {
                type: Boolean,
                default: false
            },
            isOffline:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                basket: getLocalBasket(),
                expandBasket: false,
                expandScanned: false,
                showCheckoutModal: false, 
                productToDelete: null
            }
        },
        methods:{
            formatPrice,
            deleteProduct(){
                decreaseProductFromLocalBasket(this.productToDelete)
                sessionStorage.setItem("DKC_food_suspect_basket", true);
                this.$parent.$parent.basketChange();
                this.basket = getLocalBasket()
                this.productToDelete = null;
                this.showCheckoutModal = false
            },
            toPaymentMethod(){
                basket.updateBasket(newBasket => {
                    localStorage.setItem("DKC_basket", JSON.stringify(newBasket));
                    this.$emit('basketChgt');
                    this.$router.push(localStorage.getItem("DKC_base") + '/paymentMethods');
                })
            },
            toLoyaltyScan(){
                basket.updateBasket(newBasket => {
                    localStorage.setItem("DKC_basket", JSON.stringify(newBasket));
                    this.$emit('basketChgt');
                    this.$router.push(localStorage.getItem("DKC_base") + '/scan?scanLoyaltyCard=true');
                })
            },
            handleCheckoutClick(){
                if(!this.inPaymentMethods && !this.basketIsEmpty && !this.isOffline){
                    this.showCheckoutModal = true
                }else if(this.inPaymentMethods && !this.disableCheckoutButton){
                    this.$emit("goToPayment")
                }
            }
        },
        computed: {
            basketIsEmpty(){
                return this.basket === null || this.basket.products.length == 0;
            }
        },
        watch: {
            scannedProduct(){
                this.expandBasket = false;
                this.expandScanned = true;
                this.basket = getLocalBasket()
                setTimeout(() => this.expandScanned = false, 5000)
            }
        },

    }
</script>