<template>
    <div class="steps">
        <template v-for="(step, key) in steps" >
            <div :key="'step-' + key" :class="{'element': true, 'active': active === step.id, 'mr-2': showNbrProducts(step) }">
                <i v-if="step.icon" :class="step.icon"></i>
                <div class="step-text-wrapper">
                    <span v-if="step.label" class="text">{{step.label}}</span>
                    <span v-if="showNbrProducts(step)" class="nbr-products">{{nbrProducts}}</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
    export default {
        props:{
            steps: Array,
            active: Number,
            nbrProducts: Number
        },
        methods:{
            showNbrProducts(step){
                return (this.active == 1 || this.active == 2) && step.id == 1
            }

        }
    }
</script>