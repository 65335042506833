<template>
    <div class="product-bloc">
        <div :class="{'product-wrapper': true, 'main-color-border': withBorder,'full-width': fullWidth}">
            <div class="product-img" :style="{backgroundImage: `url('${productImgUrl}')`}"></div>
            <div class="product-infos-wrapper">
                <div class="product-infos">
                    <span class="product-name">{{product.nameEn}}</span>
                    <span v-show="false" class="product-old-price">{{formattedPrice(product.price * 1.5)}}€</span>
                </div>
                <div class="product-more-infos">
                    <img v-if="universe == '2'" class="product-nutriscore" :src="require(`@/assets/img/nutriscore/${getNutriscore(this.product.descriptionEn)}.png`)" alt="Nutriscore : A">
                    <span class="product-price">{{formattedPrice(product.price)}}€</span>
                </div>
            </div>
            <div class="product-qty-wrapper">
                <span class="icon-plus icon product-plus-icon" v-if="!hideQty" @click="incQty"></span>
                <span class="product-qty">{{qty}}</span>
                <span class="icon-moins icon product-minus-icon" v-if="!hideQty" @click="decQty"></span>
            </div>
        </div>
    </div>
</template>
<script>
import {getProductQuantity, addProductToLocalBasket, decreaseProductFromLocalBasket} from "@/assets/js/Food/Basket"
import {getConfig, getNutriscore} from "@/assets/js/Utils"
export default {
    name: "ProductBloc", 
    props: {
        product: Object,
        withBorder: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        hideQty:{
            type: Boolean,
            default: false
        },
        forcedQty:{
            type: Number
        }
    },
    data(){
        return{
            qty: this.getQty(),
            universe: localStorage.getItem("DKC_univers")
        }
    },
    methods:{
        incQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            addProductToLocalBasket(this.product)
            this.qty = this.getQty()
            this.$emit("updateQty")
        },
        decQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            decreaseProductFromLocalBasket(this.product)
            this.qty = this.getQty()
            this.$emit("updateQty")
        }, 
        formattedPrice(price){
            return price.toFixed(2).toString().replace(".", ",")
        },
        getQty(){
            if(this.forcedQty){
                return this.forcedQty;
            }else{
                return getProductQuantity(this.product)
            }
        },
        getNutriscore
    },
    computed:{
        productImgUrl(){
            return `${getConfig().url_img}/${this.product.image}`
        }
    }
}
</script>